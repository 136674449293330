import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/seo';
import { PriceBlock } from '../components/PriceTable';
import Accordian from '../components/Accordian';

import faqJSON from '../data/faqData';
import priceData from '../data/tierData';
import { getObjectFromArray } from '../utilities/arrayFunctions';
import FeatureTable from '../components/FeatureTable';

const PricePage = () => {
	React.useEffect(() => window.scrollTo(0, 0), []);
	const seo = getObjectFromArray(priceData, 'name', 'seo');
	return (
		<Layout>
			<SEO pageTitle={seo.title} description={seo.description} />

			<div className="pt-24 pb-8">
				<div className="mt-12 pb-4 text-3xl font-bold lg:text-5xl textBlock">
					Start building better products today.
				</div>
				<div className="mb-8 lg:text-xl textBlock">Get started in 5 minutes.</div>

				<PriceBlock data="tierOne" />
				<FeatureTable />
			</div>

			<section className="max-w-4xl p-8 mx-auto my-16">
				<div className="mx-8 my-4 text-4xl">Frequently asked questions</div>
				<div className="flex justify-center flex-auto ">
					<Accordian data={faqJSON} />
				</div>
			</section>
		</Layout>
	);
};

export default PricePage;
