import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import priceData from '../data/tierData';
import { getObjectFromArray } from '../utilities/arrayFunctions';
import { iconLookup } from '../utilities/iconLookup';
import { RegisterButtonLink } from './Register';

function PriceTable() {
	const tierOne = getObjectFromArray(priceData, 'name', 'tierOne');
	const tierTwo = getObjectFromArray(priceData, 'name', 'tierTwo');
	const tierThree = getObjectFromArray(priceData, 'name', 'tierThree');

	return (
		<section className="py-8 ">
			<div className="px-2 pt-4 pb-12 mx-auto text-gray-800">
				<div className="flex flex-col justify-center pt-12 my-12 sm:flex-row sm:my-4">
					<div className="flex flex-col w-5/6 mx-auto mt-4 bg-white rounded-none lg:w-1/4 lg:mx-0 lg:rounded-l-lg">
						<div className="flex-1 overflow-hidden text-gray-600 bg-white rounded-t rounded-b-none shadow">
							<div className="p-8 text-3xl font-bold text-center border-b-4">
								{tierOne.title}
							</div>
							<ul className="w-full text-sm text-center">
								<li className="py-4 border-b">{tierOne.users}</li>
								{tierOne.featureList.map((featureData, index) => {
									return (
										<li className="py-4 border-b" key={'key' + index}>
											{featureData}
										</li>
									);
								})}
							</ul>
						</div>
						<div className="flex-none p-6 mt-auto overflow-hidden bg-white rounded-t-none rounded-b shadow">
							<div className="w-full pt-6 text-3xl font-bold text-center text-gray-600">
								{tierOne.price}
							</div>
							<div className="flex items-center justify-center">
								<button className="px-8 py-4 mx-auto my-6 font-bold text-white rounded-full shadow-lg lg:mx-0 hover:underline gradient">
									Sign Up
								</button>
							</div>
						</div>
					</div>

					<div className="z-10 flex flex-col w-5/6 mx-auto mt-4 bg-white rounded-lg shadow-lg lg:w-1/3 lg:mx-0 sm:-mt-6">
						<div className="flex-1 overflow-hidden bg-white rounded-t rounded-b-none shadow">
							<div className="w-full p-8 text-3xl font-bold text-center">
								{tierTwo.title}
							</div>
							<div className="w-full h-1 py-0 my-0 rounded-t gradient"></div>
							<ul className="w-full text-base font-bold text-center">
								<li className="py-4 border-b">{tierTwo.users}</li>
								{tierTwo.featureList.map((featureData, index) => {
									return (
										<li className="py-4 border-b" key={'key' + index}>
											{featureData}
										</li>
									);
								})}
							</ul>
						</div>
						<div className="flex-none p-6 mt-auto overflow-hidden bg-white rounded-t-none rounded-b shadow">
							<div className="w-full pt-6 text-4xl font-bold text-center">
								{tierTwo.price}
							</div>
							<div className="flex items-center justify-center">
								<button className="px-8 py-4 mx-auto my-6 font-bold text-white rounded-full shadow-lg lg:mx-0 hover:underline gradient">
									Sign Up
								</button>
							</div>
						</div>
					</div>

					<div className="flex flex-col w-5/6 mx-auto mt-4 bg-white rounded-none lg:w-1/4 lg:mx-0 lg:rounded-l-lg">
						<div className="flex-1 overflow-hidden text-gray-600 bg-white rounded-t rounded-b-none shadow">
							<div className="p-8 text-3xl font-bold text-center border-b-4">
								{tierThree.title}
							</div>
							<ul className="w-full text-sm text-center">
								<li className="py-4 border-b">{tierThree.users}</li>
								{tierThree.featureList.map((featureData, index) => {
									return (
										<li className="py-4 border-b" key={'key' + index}>
											{featureData}
										</li>
									);
								})}
							</ul>
						</div>
						<div className="flex-none p-6 mt-auto overflow-hidden bg-white rounded-t-none rounded-b shadow">
							<div className="w-full pt-6 text-3xl font-bold text-center text-gray-600">
								{tierThree.price}
							</div>
							<div className="flex items-center justify-center">
								<button className="px-8 py-4 mx-auto my-6 font-bold text-white rounded-full shadow-lg lg:mx-0 hover:underline gradient">
									Sign Up
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default PriceTable;

export const PriceBlock = ({ data }) => {
	const tier = getObjectFromArray(priceData, 'id', data);
	//splits the featurelist between top and bottom
	const featureSplit = 7;
	return (
		<section className="contrain-width-xl my-4">
			<div className="flex flex-col sm:flex-row justify-center">
				{/* Pricing Card */}
				<div className="p-10 px-16 rounded-lg shadow-2xl bg-gradient-to-t from-sky-100 to-cyan-100 text-center">
					<div className="w-full text-2xl font-semibold mb-2">{tier.title} Plan</div>
					<div className="w-full mb-6 text-lg">{tier.subtitle}</div>

					<ul className="w-fit mb-6 mx-auto text-base">
						{tier.featureList.map((featureData, index) => {
							if (index >= featureSplit) {
								return null;
							}
							return (
								<div key={index} className="flex items-center py-2">
									<div className="text-sky-500 mr-4">
										<FontAwesomeIcon icon={iconLookup.faCheck} size="lg" />
									</div>
									<li className="" key={'key' + index}>
										{featureData}
									</li>
								</div>
							);
						})}
					</ul>

					{/* Price and CTA */}
					<div className="mb-6">
						<div className="text-4xl font-bold mb-6">
							{tier.price}
							<span className="text-base font-light">/month</span>
						</div>
						<div className="flex flex-col items-center justify-center m-4">
							<RegisterButtonLink
								showCreditCardText
								isSmall
								label={tier.CTA.CTAButtonText}
								eventLocation="pricingTable"
							/>
						</div>
					</div>

					<ul className="px-2 mx-auto text-base py-2 lg:p-0 lg:w-1/2">
						{tier.featureList.map((featureData, index) => {
							if (index <= featureSplit - 1) {
								return null;
							}
							return (
								<div className="grid items-center grid-cols-4 py-2">
									<div className="flex items-center justify-center col-span-1 text-sky-500">
										<FontAwesomeIcon icon={iconLookup.faCheck} size="md" />
									</div>
									<li className="col-span-3" key={'key' + index}>
										{featureData}
									</li>
								</div>
							);
						})}
					</ul>
					{/* <div className="mx-auto mt-8">
							<LinkText link="/product/" color="gray-700" arrow isLarge>
								See all features
							</LinkText>
						</div> */}
				</div>
			</div>
		</section>
	);
};
