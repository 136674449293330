import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { iconLookup } from '../utilities/iconLookup';
import features from '../data/pricingTableFeatures.json';
import tiers from '../data/tierData.json';
import { getObjectFromArray } from '../utilities/arrayFunctions';
import { RegisterButtonLink } from './Register';

type PricingTableFeature = {
	name: string;
	excludedTiers: string[];
};

function FeatureTable() {
	const featureCategories = Object.keys(features);

	return (
		<section className="py-8">
			<div className="sticky top-16 pt-2 bg-white lg:pt-6 top-18">
				<div className="grid items-center grid-cols-3 gap-5 px-4 mx-auto bg-slate-200 lg:rounded-sm lg:w-1/2">
					<div className="col-span-2 text-gray-700 lg:text-2xl py-4 text-center">
						Full Feature Comparison
					</div>
					{/*<div className="col-span-1 text-xl text-center text-gray-700">
						Startup
	</div>*/}
					{tiers
						.filter(tier => typeof tier.id === 'string')
						.filter(tier => tier.id === 'tierOne')
						.map(tier => {
							return (
								<div
									key={tier.id}
									className="col-span-1 text-xl text-center text-gray-700"
								>
									{tier.title}
								</div>
							);
						})}
				</div>
			</div>

			<div className="grid grid-cols-3 gap-3 px-4 mx-auto lg:w-1/2">
				{featureCategories.map(cat => {
					return (
						<React.Fragment key={cat}>
							<div className="col-span-3 text-xl font-semibold text-gray-700 mt-8">
								{cat}
							</div>
							{features[cat].map((feature, i) => {
								return <FeatureRow key={i} feature={feature} />;
							})}
						</React.Fragment>
					);
				})}

				<div className="col-span-2"></div>
				{tiers
					.filter(tier => typeof tier.id === 'string')
					.filter(tier => tier.id === 'tierOne')
					.map(tier => {
						return (
							<RegisterButtonLink
								key={tier.id}
								isSmall
								label={'Sign Up'}
								eventLocation="pricing"
							/>
						);
					})}
			</div>
		</section>
	);
}

export default FeatureTable;

const FeatureRow = ({ feature }: { feature: PricingTableFeature }) => {
	return (
		<React.Fragment>
			<div className="col-span-2 text-gray-700">{feature.name}</div>
			{/*<div className="col-span-1 text-center text-gray-700">
				{feature.tier < 2 && <FontAwesomeIcon icon={iconLookup.faCheck} size="1x"/>}
	</div>*/}
			{tiers
				.filter(tier => typeof tier.id === 'string')
				.filter(tier => tier.id === 'tierOne')
				.map(tier => {
					const isChecked = !feature.excludedTiers.includes(tier.id);
					return (
						<div key={tier.id} className="col-span-1 text-center text-gray-700">
							{isChecked && <FontAwesomeIcon icon={iconLookup.faCheck} size="1x" />}
						</div>
					);
				})}
		</React.Fragment>
	);
};
